var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-full min-contents" },
    [
      _c("div", { staticClass: "container mx-auto" }, [
        _c(
          "div",
          [
            _vm.providers.length > 0
              ? [
                  _vm.providers.length > 0
                    ? _c(
                        "div",
                        { staticClass: "grid grid-cols-12 gap-x-6 mb-4" },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "col-start-6 col-span-2 mt-4 px-4 py-2 mr-1 font-bold text-xs relative",
                              class: "text-black bg-very-light-grey",
                              on: {
                                click: function ($event) {
                                  return _vm.changeSort()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("pages.administrators.commons.sort")
                                  ) +
                                  " "
                              ),
                              _c("img", {
                                staticClass: "absolute sort-image top-2",
                                class: [
                                  _vm.sort_order
                                    ? "desc"
                                    : "transform rotate-180",
                                ],
                                attrs: {
                                  src: require("@/assets/image/sort_icon.svg"),
                                },
                              }),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            _c(
              "div",
              { ref: "divRef", staticClass: "contents_list my-10" },
              [
                _vm._l(_vm.providers, function (provider, key) {
                  return _c(
                    "div",
                    {
                      key: key,
                      staticClass:
                        "grid grid-cols-12 gap-x-6 border border-gray-500 h-11 mb-4 hover:bg-gray-200",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-span-1 border-r border-gray-500 font-bold text_item_center my-1",
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm._f("padZero")(provider.id)) + " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-span-9 grid grid-cols-12 gap-x-3 text-xs",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-span-2 text_item_start" },
                            [
                              _c("img", {
                                staticClass: "w-4 h-4 inline-block mr-2",
                                attrs: {
                                  src: require("@/assets/image/icon_list-01.svg"),
                                },
                              }),
                              _c("FlTruncatableSpan", {
                                attrs: { value: provider.company_name },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-span-2 text_item_start" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: { to: "/admin/chs/" + provider.uuid },
                                },
                                [
                                  _c("img", {
                                    staticClass: "w-4 h-4 inline-block mr-2",
                                    attrs: {
                                      src: require("@/assets/image/icon_list-02.svg"),
                                    },
                                  }),
                                  _c("FlTruncatableSpan", {
                                    attrs: { value: provider.name },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-span-3 text_item_start" },
                            [
                              _c("img", {
                                staticClass: "w-4 h-4 inline-block mr-2",
                                attrs: {
                                  src: require("@/assets/image/icon_list-03.svg"),
                                },
                              }),
                              _c("FlTruncatableSpan", {
                                attrs: { value: provider.email },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-span-3 text_item_start" },
                            [
                              _c("img", {
                                staticClass: "w-4 h-4 inline-block mr-2",
                                attrs: {
                                  src: require("@/assets/image/icon_list-04.svg"),
                                },
                              }),
                              _c("FlTruncatableSpan", {
                                attrs: { value: provider.company_url },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-span-2 text_item_start" },
                            [_vm._v(" " + _vm._s(provider.plan_id) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-span-1 text_item_start flex text-xs",
                        },
                        [
                          _vm.impersonatedUserUuid == provider.uuid
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "w-24 px-1 py-1 mr-1 bg-light-grey font-bold",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "pages.administrators.commons.exit_impersonate"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _c(
                                "button",
                                {
                                  staticClass:
                                    "w-24 px-1 py-1 mr-1 bg-base-yellow font-bold",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.impersonatedLogin(
                                        "provider",
                                        provider.uuid
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "pages.administrators.commons.impersonate"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-span-1 text_item_start flex text-xs",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "w-24 py-1 mx-1 bg-brown-grey font-bold",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.checkDeleteProvider(provider)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "pages.administrators.commons.delete"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                _c("div", { ref: "RefScroll" }),
              ],
              2
            ),
          ],
          2
        ),
      ]),
      _c("NoticeModal", {
        attrs: {
          modalName: "checkDelete",
          title: "ユーザーの削除",
          contents:
            (_vm.deleteProvier ? _vm.deleteProvier.name : "") +
            "を削除します。本当に削除しますか？",
          buttons: ["キャンセル", "OK"],
        },
        on: {
          click: function ($event) {
            return _vm.removeProvider(_vm.deleteProvier)
          },
        },
      }),
      _c("NoticeModal", {
        attrs: {
          modalName: "deleteUserComplete",
          contents: "ユーザーの削除に成功しました。",
          buttons: ["OK"],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }